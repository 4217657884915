<template>
    <div class="verify__container">
        <v-card
            v-if="!verifyStatus_Pending"
            class="verify_form__container"
            max-width="500"
            height="500"
        >
            <v-img
                src="../../assets/logo-agrobofood-small.svg"
                max-height="120"
                max-width="120"
                alt="agrobofood logo"
                class="logo"
            >
            </v-img>
            <v-card-title class="d-block">
                <v-row>
                    <v-col cols="12" class="pa-0">
                        <p class="text-center text-body-1 text-md-h4 pa-2 mb-3">
                            {{ verificationTitle }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
                <p class="text-center text--primary">
                    {{ verificationText }}
                </p>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn
                    v-if="verifyStatus_Success || verifyStatus_Error"
                    :color="redirectButtonColor"
                    depressed
                    @click="loginRedirect()"
                >
                    {{ redirectButtonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
// API layer Imports
import { withAsync } from "../../helpers/withAsync";
import { apiStatus } from "../../api/constants/apiStatus.js";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus.js";
import { verifyUser } from "../../api/authApi.js";

export default {
    name: "VerifyAccount",

    data() {
        return {
            verifyStatus: apiStatus.Idle,
            verificationTitle: "",
            verificationText: "",

            redirectButtonText: "Login to continue",

            userToken: "",
            userId: "",
        };
    },
    computed: {
        ...apiStatusComputed("verifyStatus"),

        redirectButtonColor() {
            return this.verifyStatus_Success ? "primary" : "error";
        },
    },

    methods: {
        /**
         * Sends a call to the backend holding the needed data as payload. Returns success or unsuccessful message based on the backend response
         */

        async handleVerify() {
            this.verifyStatus = apiStatus.Pending;

            const payload = {
                user_id: this.userId,
                token: this.userToken,
            };

            const { response, error } = await withAsync(verifyUser, payload);

            if (error) {
                this.redirectButtonText = this.$t("Login.redirect");
                this.verificationText = this.$t("ErrorMessages.somethingWrong");
                this.verificationTitle = this.$t("General.oops");
                this.verifyStatus = apiStatus.Error;
                return;
            }

            this.verificationText = response.data;
            this.verificationTitle = this.$t("General.welcomeAgro");
            this.redirectButtonText = this.$t("Login.redirect");
            this.verifyStatus = apiStatus.Success;
        },

        loginRedirect() {
            this.$router.replace("/login");
        },
    },

    /**
     * On create get URL query params. If one or both of them do not exist then redirect the user to login
     * Else assign the values to data properties and proceed to verify user API call
     */

    created() {
        this.apiStatus = apiStatus;

        const queries = this.$route.query;
        if (!queries.tkn || !queries.usr) {
            this.loginRedirect();
            return;
        }
        this.userId = queries.usr || "";
        this.userToken = queries.tkn || "";

        this.handleVerify();
    },
};
</script>

<style scoped>
.verify__container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    background-image: linear-gradient(60deg, #42855b 0%, #e8e6d3 100%);
    background-size: 400% 400%;
    opacity: 0.8;
    animation: animateBackground 10s ease infinite;
}

.verify__container::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: -2 !important;
    background: url("../../assets/pattern-circles.svg");
}
.logo {
    transform: translate(-50px, -50px);
}
.verify_form__container {
    width: 90%;
    height: 30%;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

@keyframes animateBackground {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
</style>
